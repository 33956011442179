import { useEffect, useRef } from "react";

type Props = {
    onSuccess: () => void | undefined;
    code?: string[] | number[];
    element: Element;
};

export const useKonami = ({ onSuccess, code = [], element }: Props) => {
    let clickCount = 0;
    let clicksCode = "";

    const onSuccessRef = useRef(onSuccess);

    useEffect(() => {
        onSuccessRef.current = onSuccess;
    }, [onSuccess]);

    useEffect(() => {
        function clicks() {
            clickCount++;
            if (clickCount === 1) {
                setTimeout(() => {
                    if (clickCount === 1) {
                        clicksCode = `${clicksCode}1`;
                    } else {
                        clicksCode = `${clicksCode}2`;
                    }
                    if (clicksCode === code.join("")) {
                        onSuccessRef.current();
                    } else {
                        if (!code.join("").startsWith(clicksCode)) {
                            clicksCode = "";
                        }
                    }
                    clickCount = 0;
                }, 300);
            }
        }
        if (element) {
            element.addEventListener("click", clicks);
            return () => {
                element.removeEventListener("click", clicks);
            };
        }
    }, [element]);
};
