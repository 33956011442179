import { useTranslation } from "react-i18next";

import { Dialog } from "../Dialog";

export const DeleteLineItemDialog = ({
    onClose
}: {
    onClose: (result: boolean) => void;
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            title={t("wantToDeleteLineItem")}
            onBackgroundClick={() => onClose(false)}
            primaryButton={{
                title: t("yes"),
                action: () => onClose(true)
            }}
            secondaryButton={{
                title: t("no"),
                action: () => onClose(false)
            }}
        >
            <div className="text-center">
                {t("wantToDeleteLineItemDescription")}
            </div>
        </Dialog>
    );
};
