import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import * as svg from "./../svg";
import { Dialog } from "../Dialog";
import { useRootState } from "../state/StateProvider";

export const AgeVerificationDialog = () => {
    const { t } = useTranslation();
    const { state, dispatch } = useRootState("scan");

    useEffect(() => {
        const subState = state.subState;
        if (
            subState.kind === "ageVerification" &&
            subState.status === "success"
        ) {
            const successTimeoutMs = 3000;

            const timeoutId = window.setTimeout(
                () =>
                    dispatch({
                        kind: "addProduct",
                        payload: subState.attemptedAction
                    }),
                successTimeoutMs
            );

            return () => clearTimeout(timeoutId);
        }
    }, [state]);

    if (state.subState.kind !== "ageVerification") {
        return <></>;
    }

    switch (state.subState.status) {
        case "success":
            return (
                <Dialog
                    image={svg.greenCheckmark}
                    title={t("verificationSuccessful")}
                ></Dialog>
            );
        case "unconfirmed":
            return (
                <Dialog
                    dataTestId="ageVerificationUnconfirmed"
                    image={<div className="mb-8">{svg.scanId}</div>}
                    title={t("ageVerification")}
                    primaryButton={{
                        title: t("cancel"),
                        action: () => dispatch({ kind: "hideIdScan" })
                    }}
                >
                    <>
                        <div>
                            <Trans i18nKey="scanId" />
                        </div>
                        <div className="bg-yellow-50 p-2 text-xs text-primary-900">
                            <Trans i18nKey="holdIdSteady" />
                        </div>
                    </>
                </Dialog>
            );
        case "underage":
            return (
                <Dialog
                    image={svg.eighteenPlus}
                    title={t("sorryCantSell")}
                    primaryButton={{
                        title: t("okDismiss"),
                        action: () => dispatch({ kind: "hideIdScan" })
                    }}
                >
                    <div>{t("sorryCantSellDescription")}</div>
                </Dialog>
            );
        case "invalidId":
            return (
                <Dialog
                    image={svg.productNotFound}
                    title={t("somethingWentWrong")}
                    primaryButton={{
                        title: t("okDismiss"),
                        action: () => dispatch({ kind: "hideIdScan" })
                    }}
                >
                    <div>{t("invalidId")}</div>
                </Dialog>
            );
        case "error":
            return (
                <Dialog
                    image={svg.productNotFound}
                    title={t("somethingWentWrong")}
                    primaryButton={{
                        title: t("okDismiss"),
                        action: () => dispatch({ kind: "hideIdScan" })
                    }}
                >
                    <div>{t("pleaseTryAgain")}</div>
                </Dialog>
            );
    }
};
