export const getEzioId = () => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    if (id) {
        localStorage.setItem("ezioId", id);
        return id;
    }

    const ezioId = localStorage.getItem("ezioId");
    if (ezioId) return ezioId;

    console.warn("No ezio id provided");
    return undefined;
};
