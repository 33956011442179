// https://reference.epson-biz.com/modules/ref_charcode_en/index.php?content_id=12
export const specialCharacters: { [key: string]: number } = {
    Ç: 128,
    ü: 129,
    é: 130,
    â: 131,
    ä: 132,
    à: 133,
    ç: 135,
    ê: 136,
    ë: 137,
    è: 138,
    ï: 139,
    î: 140,
    ì: 141,
    Ä: 142,
    É: 144,
    ô: 147,
    ö: 148,
    ò: 149,
    û: 150,
    ù: 151,
    Ö: 153,
    Ü: 154,
    á: 160,
    í: 161,
    ó: 162,
    ú: 163
};

export const encode = (text: string) => {
    const encoded = text
        .split("")
        .map((char) => {
            if (specialCharacters[char]) {
                return new Uint8Array([specialCharacters[char]]);
            }
            return new TextEncoder().encode(char);
        })
        .reduce((acc, next) => acc.concat(next), new Uint8Array());

    return encoded;
};
