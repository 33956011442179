import { COMMANDS, feedAndCut, reset, setup } from "./commands";
import { encode } from "./encoder";

const alignments = ["left", "center", "right"] as const;
type Alignment = (typeof alignments)[number];
type Parts = Text[];

const MAX_LINE_WIDTH = 56;
const noCommand = new Uint8Array();

class Text {
    static Tab(element: Element) {
        element.textContent = "  ";
        return Text.FromElement(element);
    }

    static Padding(size: number, alignment: Alignment) {
        const text = Array(size).fill(" ").join("");
        return new Text(text, alignment, false, 0);
    }

    static FromElement(element: Element) {
        const alignment = (element.getAttribute?.("alignment") ??
            "left") as Alignment;
        const pad = Number(element.getAttribute?.("pad") ?? 0);
        const content = element.textContent ?? "";
        const text = content.padEnd(pad, " ");
        const bold = !!element.getAttribute("bold");

        return new Text(text, alignment, bold, pad);
    }

    constructor(
        public text: string,
        public alignment: Alignment,
        public bold: boolean,
        public pad: number
    ) {}

    isAligned(alignment: Alignment) {
        return this.alignment === alignment;
    }

    getCommand() {
        const boldCommand = this.bold ? COMMANDS.BOLD_SET : noCommand;
        return boldCommand.concat(encode(this.text));
    }
}

const getMain = (xml: string) => {
    const root = new DOMParser().parseFromString(xml, "text/xml");

    if (root.documentElement.nodeName === "parsererror") {
        throw "Error while parsing XML template.";
    }

    if (!root?.firstChild) {
        throw "Error no <main> found";
    }
    return root.firstChild;
};

export const consolePrint = (xml: string) => {
    const main = getMain(xml);

    const receipt = Object.entries(main.childNodes).reduce(
        (text, childNode) => {
            const [, node] = childNode;
            switch (node.nodeName) {
                case "line": {
                    const nodes = Object.entries(node.childNodes).map(
                        ([_, node]) => node
                    );
                    const parts = getLineParts(
                        nodes.filter(
                            (node) =>
                                node.nodeName === "text" ||
                                node.nodeName === "tab"
                        )
                    );
                    const allText = parts.map((part) => part.text).join("");
                    return text
                        .concat("\n")
                        .concat(allText + `(${allText.length})`);
                }
                case "br":
                    return text.concat("\n");
                case "drawline":
                    return text
                        .concat("\n")
                        .concat(Array(MAX_LINE_WIDTH).fill("-").join(""));
            }
            return text;
        },
        ""
    );

    console.log(receipt);
};

export const parse = (xml: string) => {
    const main = getMain(xml);

    return Object.entries(main.childNodes)
        .map(([_, node]) => {
            switch (node.nodeName) {
                case "line":
                    return parseLine(node);
                case "br":
                    return COMMANDS.NEW_LINE;
                case "drawline":
                    return COMMANDS.DRAW_LINE;
            }
            return noCommand;
        })
        .reduce((command, next) => command.concat(next), setup)
        .concat(feedAndCut);
};

const padToMaxLength = (parts: Parts) => {
    const left = parts
        .filter((part) => part.isAligned("left"))
        .map((part) => part.text)
        .join("");
    const leftLen = left.length;

    const center = parts
        .filter((part) => part.isAligned("center"))
        .map((part) => part.text)
        .join("");
    const centerLen = center.length;

    const right = parts
        .filter((part) => part.isAligned("right"))
        .map((part) => part.text)
        .join("");
    const rightLen = right.length;

    const centerStart = MAX_LINE_WIDTH / 2 - Math.floor(centerLen / 2);
    const centerEnd = MAX_LINE_WIDTH / 2 + Math.floor(centerLen / 2);

    const padLeft = centerStart - leftLen - 1;
    const padRight = MAX_LINE_WIDTH - centerEnd - rightLen;

    const firstCenter = Text.Padding(Math.max(padLeft, 0), "center");
    const lastCenter = Text.Padding(
        Math.max(padRight + Math.min(0, padLeft), 0),
        "center"
    );

    parts = [
        ...parts.filter((p) => p.isAligned("left")),
        firstCenter,
        ...parts.filter((p) => p.isAligned("center")),
        lastCenter,
        ...parts.filter((p) => p.isAligned("right"))
    ];

    return parts;
};

const getLineParts = (nodes: ChildNode[]) => {
    const parts = nodes.reduce((part, node) => {
        switch (node.nodeName) {
            case "text": {
                const element = node as Element;

                const text = Text.FromElement(element);
                part.push(text);
                break;
            }
            case "tab": {
                const element = node as Element;

                part.push(Text.Tab(element));
                break;
            }
        }
        return part;
    }, [] as Parts);

    return padToMaxLength(parts);
};

const parseLine = (line: ChildNode) => {
    const nodes = Object.entries(line.childNodes).map(([_, node]) => node);

    const parts = getLineParts(
        nodes.filter(
            (node) => node.nodeName === "text" || node.nodeName === "tab"
        )
    );

    return parts
        .reduce(
            (command, part) => command.concat(part.getCommand()).concat(reset),
            new Uint8Array()
        )
        .concat(COMMANDS.NEW_LINE);
};
