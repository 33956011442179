import { trace } from "firebase/performance";

import { getPerformanceInstance } from "../firebase";
import { ConfigApiResponse } from "../types/Config";

export class ConfigClient {
    constructor(private client: typeof fetch) {}

    public async setup(): Promise<ConfigApiResponse> {
        const t = trace(getPerformanceInstance(), "setup");
        t.start();
        const response = await this.client("/ezio/v1/setup", {
            method: "GET",
            headers: { version: import.meta.env.VITE_VERSION }
        });
        t.stop();

        return await response.json();
    }
}
