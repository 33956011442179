import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { useAnyState } from "../state/StateProvider";

export const StartOverDialog = () => {
    const { t } = useTranslation();

    const { dispatch } = useAnyState();
    const [isStartOverOpen, setIsStartOverOpen] = useState(false);

    return (
        <>
            {isStartOverOpen && (
                <Dialog
                    title={t("wantToStartOver")}
                    onBackgroundClick={() => setIsStartOverOpen(false)}
                    primaryButton={{
                        title: t("yes"),
                        action: () => dispatch({ kind: "reset" })
                    }}
                    secondaryButton={{
                        title: t("no"),
                        action: () => setIsStartOverOpen(false)
                    }}
                >
                    <div className="text-center">
                        {t("wantToStartOverDescription")}
                    </div>
                </Dialog>
            )}
            <Button
                onClick={() => setIsStartOverOpen(true)}
                label={t("startOver")}
            />
        </>
    );
};
