import { useTranslation } from "react-i18next";

import valora from "../../assets/valora.svg";

export const Initializing = () => {
    const { t } = useTranslation();

    return (
        <div className="flex h-full flex-col items-center bg-indigo-950">
            <img src={valora} alt="valora" className="mt-5" />
            <div className="grid grow place-items-center text-2xl text-white">
                {t("pleaseWait")}
            </div>
        </div>
    );
};
