import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import placeholderImage from "../../../assets/placeholder.png";
import { Product, ProductPreset } from "../../types/ProductPreset";
import { translate } from "../../utils/translations";
import { FadeIn, SlideUp } from "../Animations";
import { NumPad } from "../NumPad";
import { useRootState } from "../state/StateProvider";

const ProductPickerDialog = ({
    productPreset,
    onCancel,
    onSelect
}: {
    productPreset: ProductPreset;
    onCancel: () => void;
    onSelect: (code: string, quantity: number) => void;
}) => {
    const [product, setProduct] = useState<Product>();
    const { t } = useTranslation();
    const {
        state: { accessibilityMode }
    } = useRootState("scan");

    const { i18n } = useTranslation();

    const [currentFilter, setFilter] = useState<number>(0);

    const addProduct = (quantity: number) => {
        if (product) {
            setProduct(undefined);
            onSelect(product.ean, quantity);
        }
    };

    const products = productPreset.categories[currentFilter].products;

    const FilterTab = ({ index }: { index: number }) => {
        return (
            <div
                onClick={() => {
                    setFilter(index);
                }}
                className={`
                    flex-1
                    rounded
                    border-2
                    bg-white
                    p-2
                    text-center
                    text-xs
                    font-bold
                    capitalize
                    ${
                        index === currentFilter
                            ? "border-primary-800"
                            : "border-white"
                    }
                `}
            >
                {translate(productPreset.categories[index].translations, i18n)}
            </div>
        );
    };

    return (
        <>
            {product && (
                <NumPad
                    onConfirm={addProduct}
                    onCancel={() => setProduct(undefined)}
                />
            )}
            <div
                data-testid="productPicker"
                className="z-10 flex h-full flex-col gap-3 bg-primary-100 p-3"
            >
                <h1 className="text-3xl font-semibold leading-snug text-primary-800">
                    {translate(productPreset.translations, i18n)}
                </h1>
                <div className="flex items-stretch gap-2">
                    {productPreset.categories.length > 1 &&
                        productPreset.categories.map((_, index) => (
                            <FilterTab key={index} index={index} />
                        ))}
                </div>
                <div className="-mx-3 flex-1 overflow-y-auto">
                    <div
                        data-testid="products"
                        className={`grid ${
                            accessibilityMode ? "grid-cols-3" : "grid-cols-4"
                        } gap-2 px-3`}
                    >
                        {products.map((product) => (
                            <div
                                role="listitem"
                                data-testid={"fixtasten".concat(product.ean)}
                                key={translate(product.translations, i18n)}
                                onClick={() => setProduct(product)}
                                className={`
                                    press-effect grid
                                    place-items-center
                                    py-2
                                    ${
                                        accessibilityMode
                                            ? "grid-cols-2 gap-x-1"
                                            : "grid-cols-1"
                                    }
                                    rounded
                                    bg-white
                                    px-4
                                    text-center text-xs font-semibold
                                    text-primary-800
                                `}
                            >
                                {
                                    <img
                                        src={
                                            product.imageUrl ?? placeholderImage
                                        }
                                        className="h-12"
                                    />
                                }
                                <span className="w-full overflow-hidden text-ellipsis capitalize">
                                    {translate(
                                        product.translations,
                                        i18n
                                    ).toLowerCase()}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <button
                    className="press-effect flex-none rounded bg-primary-500 p-2 text-center font-semibold leading-loose text-white"
                    type="button"
                    onClick={() => onCancel()}
                >
                    {t("back")}
                </button>
            </div>
        </>
    );
};

export const ProductPicker = () => {
    const { state, dispatch } = useRootState("scan");

    const { subState, accessibilityMode } = state;

    return (
        <AnimatePresence>
            {subState.kind === "productPicker" && (
                <>
                    <FadeIn className="pt-30 fixed inset-0 bg-primary-900 bg-opacity-75" />
                    <SlideUp
                        key="select"
                        className={`fixed inset-x-0 bottom-0 ${
                            accessibilityMode ? "top-80" : "top-12"
                        } z-10 rounded-t-xl`}
                    >
                        <ProductPickerDialog
                            productPreset={subState.productPreset}
                            onSelect={(barcode, quantity) => {
                                dispatch({
                                    kind: "addProduct",
                                    payload: {
                                        kind: "manuallyAdded",
                                        code: barcode,
                                        quantity,
                                        analyticsSource: "fixtasten"
                                    }
                                });
                            }}
                            onCancel={() => dispatch({ kind: "hideProducts" })}
                        />
                    </SlideUp>
                </>
            )}
        </AnimatePresence>
    );
};
