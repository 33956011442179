import { ConfigProvider } from "./components/ConfigProvider";
import { Dialog } from "./components/Dialog";
import ErrorBoundary from "./components/ErrorBoundary";
import { Header } from "./components/header/Header";
import { PageSwitcher } from "./components/PageSwitcher";
import { StateProvider } from "./components/state/StateProvider";
import { Timeout } from "./components/Timeout";
import { VersionController } from "./components/VersionController";
import { useLamp } from "./Lamp";
import "./firebase";
import { usePrinter } from "./Printer/printer";

function App() {
    const { connected, enabled, connect } = useLamp();

    const {
        connect: connectPrinter,
        connected: printerConnected,
        enabled: printerEnabled
    } = usePrinter();

    const startLamp = () => {
        connect().then(console.log);
    };

    const startPrinter = () => {
        connectPrinter().then(console.log);
    };

    const dev = import.meta.env.DEV;

    return (
        <ConfigProvider>
            <StateProvider>
                <VersionController>
                    <Timeout>
                        <ErrorBoundary>
                            {!dev && !connected && enabled && (
                                <Dialog
                                    title="Lamp not connected"
                                    primaryButton={{
                                        title: "Connect",
                                        action: startLamp
                                    }}
                                />
                            )}
                            {!dev && !printerConnected && printerEnabled && (
                                <Dialog
                                    title="Printer not connected"
                                    primaryButton={{
                                        title: "Connect",
                                        action: startPrinter
                                    }}
                                />
                            )}
                            <Header />
                            <PageSwitcher />
                        </ErrorBoundary>
                    </Timeout>
                </VersionController>
            </StateProvider>
        </ConfigProvider>
    );
}

export default App;
