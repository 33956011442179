import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { useLamp } from "../../Lamp";
import { usePrinter } from "../../Printer/printer";
import { ScannedCode } from "../../types/Action";
import { Button } from "../Button";
import useBarcodeScanner from "../hooks/useBarcodeScanner";
import { useRootAndSubState } from "../state/StateProvider";

export const Hardware = () => {
    useRootAndSubState("operator", "hardware");
    const { setColor } = useLamp();
    const { print } = usePrinter();
    const { t } = useTranslation();

    const scannerTextarea = useRef<HTMLTextAreaElement>(null);

    const onScan = ({ code }: ScannedCode) => {
        if (scannerTextarea.current) {
            scannerTextarea.current.value = code;
        }
    };

    useBarcodeScanner({ onScan });

    return (
        <div className="m-4 flex h-full flex-col gap-4">
            <div className="text-2xl font-semibold">
                {t("operator.hardware.title")}
            </div>
            <div className="flex flex-col gap-4">
                <div>{t("operator.hardware.lamp")}</div>
                <div className="flex gap-4">
                    <Button
                        onClick={() => {
                            setColor("Red");
                        }}
                        label={t("operator.hardware.red")}
                    />
                    <Button
                        onClick={() => {
                            setColor("Green");
                        }}
                        label={t("operator.hardware.green")}
                    />
                    <Button
                        onClick={() => {
                            setColor("Brand");
                        }}
                        label={t("operator.hardware.brand")}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <div>{t("operator.hardware.printer")}</div>
                <div className="flex gap-4">
                    <Button
                        onClick={() => {
                            print(dummyReceipt);
                        }}
                        label={t("operator.hardware.print")}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <div>{t("operator.hardware.scanner")}</div>
                <Button
                    className="w-min"
                    onClick={() => {
                        if (scannerTextarea.current) {
                            scannerTextarea.current.value = "";
                        }
                    }}
                    label={t("operator.hardware.clear")}
                />
                <textarea
                    className="bg-gray-100"
                    ref={scannerTextarea}
                    rows={3}
                    autoFocus
                />
            </div>
        </div>
    );
};

const dummyReceipt = `<?xml version="1.0" encoding="UTF-8"?>
<main lng="de">
  <line>
    <text alignment="left">Valora Store </text>
  </line>
  <line>
    <text bold="true" alignment="left">01 January 2024 00:00</text>
  </line>
  <drawline />
  <br />
  <line>
    <text alignment="center">Valora Store </text>
  </line>
  <line>
    <text alignment="center">Valora Schweiz AG</text>
  </line>
  <line>
    <text alignment="center">CHE-103.468.185</text>
  </line>
  <br />
  <line>
    <text alignment="left">Kauf-ID: xxxxx-xxxxx</text>
    <text alignment="right">CHF</text>
    <tab alignment="right" />
    <tab alignment="right" />
    <tab alignment="right" />
  </line>
  <line>
    <text pad="4" alignment="left">1x</text>
    <tab alignment="left" />
    <text alignment="left">TRAGTASCHE 0.40 0.40 CHF</text>
    <text pad="6" alignment="right">0.40</text>
    <tab alignment="right" />
    <text alignment="right">AE</text>
  </line>
  <drawline />
  <br />
  <line>
    <text alignment="left">TOTAL [1]</text>
    <text alignment="center">CHF</text>
    <text alignment="right">0.40</text>
    <tab alignment="right" />
    <tab alignment="right" />
  </line>
  <line>
    <text alignment="left">VISA</text>
    <text alignment="center">CHF</text>
    <text alignment="right">0.40</text>
    <tab alignment="right" />
    <tab alignment="right" />
  </line>
  <drawline />
  <br />
  <line>
    <text alignment="left">Nettobetrag</text>
    <text alignment="right">CHF</text>
    <tab alignment="right" />
  </line>
  <line>
    <text alignment="left">AE=MWST</text>
    <text pad="6" alignment="center">8.10%</text>
    <tab alignment="center" />
    <text alignment="center">=</text>
    <text pad="6" alignment="right">0.03</text>
  </line>
  <drawline />
  <br />
  <line>
    <text bold="true" alignment="center">Buchung</text>
  </line>
  <line>
    <text bold="true" alignment="center">VISA CREDIT</text>
  </line>
  <line>
    <text alignment="left">XXXX XXXX XXXX XXXX</text>
  </line>
  <br />
  <line>
    <text bold="true" alignment="left">01.01.2024</text>
    <text bold="true" alignment="right">00:00:00</text>
  </line>
  <line>
    <text alignment="left">Trm-Id:</text>
    <text alignment="right">xxxxxxxx</text>
  </line>
  <line>
    <text alignment="left">Akt-Id:</text>
    <text alignment="right">x</text>
  </line>
  <line>
    <text alignment="left">AID:</text>
    <text alignment="right">Axxxxxxxxxxxxx</text>
  </line>
  <line>
    <text alignment="left">Trx. Seq-Cnt:</text>
    <text alignment="right">xxxxx</text>
  </line>
  <line>
    <text alignment="left">Trx. Ref-No:</text>
    <text alignment="right">xxxxxxxxxxx</text>
  </line>
  <line>
    <text alignment="left">Auth. Code:</text>
    <text alignment="right">xxxxxx</text>
  </line>
  <line>
    <text alignment="left">Acq-Id:</text>
    <text alignment="right">x</text>
  </line>
  <br />
  <line>
    <text alignment="left">Total-EFT CHF:</text>
    <text alignment="right">0.40</text>
  </line>
</main>
    `;
