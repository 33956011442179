import { useTranslation } from "react-i18next";

import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { useRootState } from "../state/StateProvider";
import { questionMark } from "../svg";

export const OperatorModeDialog = () => {
    const {
        state: { subState },
        dispatch
    } = useRootState("welcome");
    const isDialogOpen = subState.kind === "operatorVerification";

    return (
        <>
            {isDialogOpen && <DialogContent />}
            <Button
                className="z-10"
                onClick={() => {
                    dispatch({ kind: "showOperatorBadgeVerification" });
                }}
                label={
                    <div className="flex h-full items-center text-primary-500 ">
                        {questionMark}
                    </div>
                }
            />
        </>
    );
};

const DialogContent = () => {
    const { t } = useTranslation();
    const { dispatch } = useRootState("welcome");

    const handleCloseDialog = () => {
        dispatch({ kind: "hideOperatorBadgeVerification" });
    };

    return (
        <Dialog
            title={t("operatorModeDialog.title")}
            onBackgroundClick={handleCloseDialog}
            secondaryButton={{
                action: handleCloseDialog,
                title: t("cancel")
            }}
        >
            <div className="text-center">
                {t("operatorModeDialog.description")}
            </div>
        </Dialog>
    );
};
