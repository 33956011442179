export const debounce = (
    func: () => void,
    wait = 100,
    when: "immediate" | "end"
) => {
    let timeout: number | undefined, timestamp: number;

    const later = () => {
        const last = Date.now() - timestamp;

        if (last < wait && last >= 0) {
            timeout = window.setTimeout(later, wait - last);
        } else {
            timeout = undefined;
            if (when === "end") {
                func();
            }
        }
    };

    const debounced = () => {
        timestamp = Date.now();
        const callNow = when === "immediate" && !timeout;
        if (!timeout) timeout = window.setTimeout(later, wait);
        if (callNow) {
            func();
        }
        return;
    };

    return debounced;
};

export const debounceWithId = (function () {
    const timers: Record<string, ReturnType<typeof setTimeout>> = {};

    return function (callback: VoidFunction, id: string, wait = 500) {
        if (timers?.[id]) {
            clearTimeout(timers[id]);
            delete timers[id];
        }

        timers[id] = setTimeout(callback, wait);
    };
})();
