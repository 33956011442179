import { QRCodeSVG } from "qrcode.react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "./Button";
import { debounce } from "./debounce";
import { Spinner } from "./Spinner";
import { useRootState } from "./state/StateProvider";

export const Receipt = () => {
    const { state, dispatch } = useRootState("checkoutSuccess");

    const { subState, orderId } = state;

    const { t } = useTranslation();

    const [printed, setPrinted] = useState(false);

    const printReceipt = async () => {
        await dispatch({ kind: "printReceipt", orderId });
        setPrinted(true);
    };

    const debouncedPrint = useMemo(
        () => debounce(printReceipt, 500, "immediate"),
        []
    );

    const PrintButton = () => {
        return printed ? (
            <div data-testid="receiptPrinted">{t("receiptPrinted")} </div>
        ) : (
            <Button
                className="rounded-none px-8"
                onClick={debouncedPrint}
                label={t("printReceipt")}
            />
        );
    };

    return (
        <div className="flex w-80 flex-col items-center gap-6 rounded-xl bg-white p-6">
            {subState.kind === "receiptSuccess" ? (
                <QRCodeSVG size={225} value={subState.receiptUrl} />
            ) : (
                <div className="grid h-[225px] place-content-center">
                    <Spinner />
                </div>
            )}
            <div className="text-center text-xl font-bold text-primary-800">
                {t("scanQRCode")}
            </div>
            <PrintButton />
        </div>
    );
};
