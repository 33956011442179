import { useTranslation } from "react-i18next";

import { InsightsGroupBy } from "../../types/Operator";
import { useRootAndSubState } from "../state/StateProvider";

export const Insights = () => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useRootAndSubState("operator", "insights");

    const { date, insights, groupBy } = state;

    const categories: { [key: string]: string } = i18n.getResource(
        i18n.language,
        "translation",
        "productCategory"
    );

    const formatValue = (value: string, unit?: string) =>
        unit ? `${unit} ${value}` : value;

    return (
        <div className="m-4 flex flex-col gap-4">
            <div className="text-3xl font-semibold text-primary-900">
                {t("insight", { count: 2 })}
            </div>
            <div className="flex gap-10 text-xl text-primary-900">
                <input
                    type="date"
                    lang={i18n.language}
                    value={date}
                    onChange={(e) =>
                        dispatch({
                            kind: "showInsights",
                            date: e.target.value,
                            groupBy
                        })
                    }
                />
                <select
                    value={groupBy}
                    onChange={(e) =>
                        dispatch({
                            kind: "showInsights",
                            date,
                            groupBy: e.target.value as InsightsGroupBy
                        })
                    }
                >
                    {["scoId", "category"].map((key) => (
                        <option key={key} value={key}>
                            {t(`insightGrouping.${key}`)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex h-full flex-col gap-3">
                <div className="grid grid-cols-1 gap-2 px-3">
                    {[
                        {
                            label: "totalRevenue",
                            value: formatValue(insights.total, insights.unit)
                        },
                        ...Object.entries(insights.breakdown ?? {}).map(
                            ([label, breakDownValue]) => ({
                                label,
                                value: formatValue(
                                    breakDownValue,
                                    insights.unit
                                )
                            })
                        )
                    ].map(({ label, value }) => (
                        <div
                            key={1}
                            className={`
                                    press-effect
                                    grid
                                    grid-cols-4
                                    rounded
                                    bg-gray-100
                                    px-4
                                    py-5
                                    align-text-bottom
                                    text-lg
                                    text-black
                                    first:bg-gray-50
                                    first:font-semibold`}
                        >
                            <div className="col-span-3 col-start-1">
                                {categories && categories[label] ? (
                                    <>
                                        <span className="font-semibold">
                                            {label}
                                        </span>{" "}
                                        {categories[label]}
                                    </>
                                ) : (
                                    t(label)
                                )}
                            </div>
                            <div>{value}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
