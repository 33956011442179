import { Component, ContextType, ErrorInfo, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "./Dialog";
import { StateContext } from "./state/StateProvider";
import * as svg from "./svg";
import { useLamp } from "../Lamp";
import { Tracker } from "../track";

const ErrorDialog = () => {
    const { t } = useTranslation();
    const { setColor } = useLamp();
    setColor("Red");

    return (
        <Dialog
            image={svg.productNotFound}
            title={t("somethingWentWrong")}
            primaryButton={{
                title: t("reload"),
                action: () => {
                    setColor("Green");
                    window.location.reload();
                }
            }}
        />
    );
};

class ErrorBoundary extends Component<PropsWithChildren> {
    static contextType = StateContext;

    private getContext() {
        return this.context as ContextType<typeof StateContext>;
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.getContext()?.dispatch({
            kind: "showError"
        });
        new Tracker().track("exception", {
            description: error.message,
            stack: error.stack,
            fatal: false
        });
        console.error("Uncaught error:", error, errorInfo);
    }

    private promiseRejectionHandler = (_: PromiseRejectionEvent) => {
        this.getContext()?.dispatch({
            kind: "showError"
        });
    };

    componentDidMount() {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener(
            "unhandledrejection",
            this.promiseRejectionHandler
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            "unhandledrejection",
            this.promiseRejectionHandler
        );
    }

    public render() {
        const { state } = this.getContext();
        if (state?.kind === "error") {
            return <ErrorDialog />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
