export class LogClient {
    constructor(private client: typeof fetch) {}

    public async info(message: string) {
        await this.client("/ezio/v1/log", {
            method: "POST",
            body: JSON.stringify({
                severity: "info",
                message,
                timestamp: new Date().toISOString()
            })
        });
    }
}
