import { useEffect, useState } from "react";

export function useDelayedCondition(condition: boolean, delay: number) {
    const [isHanging, setIsHanging] = useState(false);

    useEffect(() => {
        let timeout: number;
        if (condition) {
            timeout = window.setTimeout(() => setIsHanging(true), delay);
        }
        return () => {
            setIsHanging(false);
            window.clearTimeout(timeout);
        };
    }, [condition]);

    return isHanging;
}
