import { useCallback, useEffect, useRef } from "react";

import { ScannedCode } from "../../types/Action";
import { RootState } from "../state/StateProvider";

const convertScanResultToCode = (scanResult: string): ScannedCode => {
    const [code, deviceIdentifier] = scanResult
        .replaceAll("[LF]", "")
        .split("-");

    const deviceName = (() => {
        switch (deviceIdentifier) {
            case "S":
                return "stationary";
            case "P":
                return "pistol";
        }
    })();

    return { kind: "scanned", code, device: deviceName };
};

export const requiresOperatorBadgeScan = (state: RootState) =>
    state.kind === "welcome" && state.subState.kind === "operatorVerification";

export const isValidOperatorBadge = (scan: ScannedCode) => {
    return scan.code === "SOMECODE";
};

export const isValidTransferCode = (scan: ScannedCode) => {
    return scan.code.startsWith("transfer_");
};

type UseBarcodeScannerProps = {
    onScan: (scannedCode: ScannedCode) => void;
};

const useBarcodeScanner = ({ onScan }: UseBarcodeScannerProps) => {
    const buffer = useRef<string[]>([]);

    const scanRef = useRef(onScan);

    useEffect(() => {
        scanRef.current = onScan;
    }, [onScan]);

    const onComplete = (code: string) => {
        scanRef.current(convertScanResultToCode(code));
    };

    const clearBuffer = () => {
        buffer.current = [];
    };

    const onPaste = useCallback((event: ClipboardEvent) => {
        const value = event.clipboardData?.getData("text/plain");
        if (value) buffer.current = [value];
    }, []);

    const onKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === "#") {
            onComplete(buffer.current.join(""));
            clearBuffer();
            return;
        }
        buffer.current.push(event.key);
    }, []);

    useEffect(() => {
        if (import.meta.env.PROD) {
            document.addEventListener("keypress", onKeyDown);
            return () => document.removeEventListener("keypress", onKeyDown);
        }

        return addTestListeners();
    }, [onKeyDown]);

    const addTestListeners = () => {
        document.addEventListener("keypress", onKeyDown);
        document.addEventListener("paste", onPaste);
        window.sendBarcode = window.sendBarcode || onComplete;
        window.scanId =
            window.scanId ||
            (() => {
                onComplete(
                    "ARCHESE00010962211161541<<<<<<8007050F2512314FRA<<<<<<<<<<<2LANDOLT<<CAMILLE<<<<<<<<<<<<<<"
                );
            });
        window.scanUnderageId =
            window.scanUnderageId ||
            (() => {
                onComplete(
                    "ARCHESE00010962211161541<<<<<<1007050F2512314FRA<<<<<<<<<<<2LANDOLT<<CAMILLE<<<<<<<<<<<<<<"
                );
            });
        return () => {
            document.removeEventListener("keypress", onKeyDown);
            document.removeEventListener("paste", onPaste);
            delete window.sendBarcode;
            delete window.scanId;
            delete window.scanUnderageId;
        };
    };
};

export default useBarcodeScanner;
