import { parse } from "mrz";

import { IdentityDocument } from "./types/Cart";

export type MrzParseError = "UnrecognizedMrz";

const parseMrz = (code: string): IdentityDocument => {
    const parseBirthdate = (birthdate: string) => {
        let birthYear = parseInt(birthdate.substring(0, 2));
        const currentYear = new Date().getFullYear();
        const currentYearLastTwoDigits = currentYear % 100;
        const currentCentury = currentYear - currentYearLastTwoDigits;
        if (birthYear >= currentYearLastTwoDigits) {
            birthYear += currentCentury - 100;
        } else {
            birthYear += currentCentury;
        }

        return `${birthYear}-${birthdate.substring(2, 4)}-${birthdate.substring(
            4
        )}`;
    };

    const typeOneLength = 90;
    const typeTwoLength = 72;
    const typeThreeLength = 88;

    const swissDriversLicenseLength = 69;
    const rawMrz = code.replaceAll(";", "<");

    let mrz;

    if (rawMrz.length === typeOneLength) {
        mrz = [
            rawMrz.substring(0, 30),
            rawMrz.substring(30, 60),
            rawMrz.substring(60)
        ];
    } else if (rawMrz.length === typeTwoLength) {
        mrz = [rawMrz.substring(0, 36), rawMrz.substring(36)];
    } else if (rawMrz.length === typeThreeLength) {
        mrz = [rawMrz.substring(0, 44), rawMrz.substring(44)];
    } else if (rawMrz.length === swissDriversLicenseLength) {
        mrz = [
            rawMrz.substring(0, 9),
            rawMrz.substring(9, 39),
            rawMrz.substring(39)
        ];
    } else {
        throw "UnrecognizedMrz" as MrzParseError;
    }

    const parsed = parse(mrz);

    if (
        !parsed.fields.birthDate ||
        !parsed.fields.issuingState ||
        !parsed.fields.documentNumber
    ) {
        throw "UnrecognizedMrz" as MrzParseError;
    }

    return {
        birthdate: parseBirthdate(parsed.fields.birthDate),
        issuingCountryIso: parsed.fields.issuingState,
        documentId: parsed.fields.documentNumber
    };
};

const parseModernSwissLicense = (swissLicense: string[]): IdentityDocument => {
    return {
        birthdate: `${swissLicense.at(3)}-${swissLicense.at(
            2
        )}-${swissLicense.at(1)}`,
        issuingCountryIso: "CHE",
        documentId: swissLicense.at(-1) || ""
    };
};

export const mrzToDocumentId = (code: string): IdentityDocument => {
    const modernSwissLicenseFormat =
        /^[^;]+;[^;]+;(\d{2})\.(\d{2})\.(\d{4});([^;]+)$/;

    const modernSwissLicense = code.match(modernSwissLicenseFormat);

    if (modernSwissLicense) {
        return parseModernSwissLicense(modernSwissLicense);
    } else {
        return parseMrz(code);
    }
};
