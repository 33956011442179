import { AnimatePresence, useIsPresent } from "framer-motion";
import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

import { FadeIn, Zoom } from "./Animations";
import { useConfig } from "./ConfigProvider";
import { useAnyState } from "./state/StateProvider";

const Content = ({
    children,
    onBackgroundClick,
    onCloseComplete
}: PropsWithChildren<{
    onBackgroundClick?: () => void;
    onCloseComplete?: () => void;
}>) => {
    const isPresent = useIsPresent();

    const {
        state: { accessibilityMode }
    } = useAnyState();

    return (
        <FadeIn
            onAnimationComplete={() => !isPresent && onCloseComplete?.()}
            className="fixed inset-0 z-10 grid place-content-center overflow-y-auto bg-primary-900 bg-opacity-75"
            onClick={(e) => {
                e.preventDefault();
                onBackgroundClick?.();
            }}
        >
            {accessibilityMode && <div className="h-72"></div>}
            <Zoom>{children}</Zoom>
        </FadeIn>
    );
};

export const Modal = ({
    children,
    isOpen = true,
    onBackgroundClick,
    onCloseComplete
}: PropsWithChildren<{
    isOpen: boolean;
    onBackgroundClick?: () => void;
    onCloseComplete?: () => void;
}>) => {
    const { tenant } = useConfig();

    return createPortal(
        <div data-theme={tenant}>
            <AnimatePresence>
                {isOpen && (
                    <Content
                        onBackgroundClick={onBackgroundClick}
                        onCloseComplete={onCloseComplete}
                    >
                        {children}
                    </Content>
                )}
            </AnimatePresence>
        </div>,
        document.body
    );
};
