import { trace } from "firebase/performance";

import { getPerformanceInstance } from "../firebase";
import { AddProduct } from "../types/Action";
import {
    CartError,
    Cart,
    IdentityDocument,
    CheckoutResponse,
    CartTransferError
} from "../types/Cart";

const catchCartError = async (response: Response) => {
    if (response.ok) return response;

    const error = (await response.json()) as CartError;
    throw error;
};

const catchCartTransferError = async (response: Response) => {
    if (response.ok) return response;
    const error = (await response.json()) as CartTransferError;
    throw error;
};

export const getEventPayload = (event: AddProduct) => {
    switch (event.payload.kind) {
        case "scanned":
            return {
                barcode: event.payload.code,
                quantity: 1,
                source: "scan",
                device: event.payload.device
            };
        case "manuallyAdded":
            return {
                barcode: event.payload.code,
                quantity: event.payload.quantity,
                source: event.payload.analyticsSource
            };
    }
};

export const getQuantity = (event: AddProduct) => {
    switch (event.payload.kind) {
        case "scanned":
            return 1;
        case "manuallyAdded":
            return event.payload.quantity;
    }
};

export class CartClient {
    constructor(private client: typeof fetch) {}

    public async checkin(): Promise<Cart> {
        const t = trace(getPerformanceInstance(), "checkin");
        t.start();
        const response = await this.client("/ezio/v1/start", {
            method: "POST",
            headers: { version: import.meta.env.VITE_VERSION }
        });
        t.stop();

        return await response.json();
    }

    public async add(
        barcode: string,
        quantity = 1,
        addOnOptionIds?: number[]
    ): Promise<Cart> {
        const t = trace(getPerformanceInstance(), "addProduct");
        t.start();
        const cart = await this.client("/ezio/v1/cart/products", {
            method: "POST",
            body: JSON.stringify({
                barcode,
                quantity,
                addOnOptionIds
            })
        })
            .then(catchCartError)
            .then((response) => response.json());
        t.stop();
        return cart;
    }

    public async updateLineItem(id: string, quantity = 1): Promise<Cart> {
        const cart = await this.client(`/ezio/v1/cart/line-items/${id}`, {
            method: "PUT",
            body: JSON.stringify({ quantity })
        })
            .then(catchCartError)
            .then((response) => response.json());

        return cart;
    }

    public async deleteLineItem(id: string): Promise<Cart> {
        const response = await this.client(`/ezio/v1/cart/line-items/${id}`, {
            method: "DELETE"
        });

        return await response.json();
    }

    public async scanId(identityDocument: IdentityDocument): Promise<Cart> {
        const response = await this.client(`/ezio/v1/cart/identity`, {
            method: "POST",
            body: JSON.stringify(identityDocument)
        })
            .then(catchCartError)
            .then((response) => response.json());

        return response;
    }

    public async checkout(language: string, simulatePaymentResponse?: string) {
        const debug = simulatePaymentResponse
            ? `?simulatePaymentResponse=${simulatePaymentResponse}`
            : "";
        const t = trace(getPerformanceInstance(), "checkout");
        t.start();
        const response: CheckoutResponse = await this.client(
            `/ezio/v1/checkout${debug}`,
            {
                method: "POST",
                headers: {
                    "Accept-Language": language
                },
                signal: AbortSignal.timeout(100_000)
            }
        )
            .then(catchCartError)
            .then((result) => result.json());
        t.stop();
        return response;
    }

    public async transfer(code: string) {
        const t = trace(getPerformanceInstance(), "transfer");
        t.start();
        const response: Cart = await this.client(`/ezio/v1/cart/transfer`, {
            method: "PUT",
            body: JSON.stringify({ code })
        })
            .then(catchCartTransferError)
            .then((result) => result.json());
        t.stop();
        return response;
    }
}
