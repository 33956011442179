import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "./Modal";

type NumPadProps = {
    defaultValue?: string;
    onConfirm: (value: number) => void;
    onCancel: () => void;
    max?: number;
};

export const NumPad = ({
    defaultValue = "1",
    onConfirm,
    onCancel,
    max = 99
}: NumPadProps) => {
    const [isOpen, setIsOpen] = useState(true);

    const onCloseCallbackRef = useRef<() => void>();

    const closeWith = (action: () => void) => () => {
        onCloseCallbackRef.current = action;
        setIsOpen(false);
    };

    const [value, setValue] = useState(defaultValue);

    const [firstChange, setFirstChange] = useState(true);

    const { t } = useTranslation();

    const appendOrReplaceValue = (digit: string) => {
        if (firstChange) {
            setValue(digit);
            setFirstChange(false);
            return;
        }
        setValue((value) =>
            max >= Number(value + digit) ? value + digit : value
        );
    };

    const digits = [
        ...Array.from({ length: 9 }).map((_, index) => ({
            key: index + 1,
            digit: (index + 1).toString(),
            onClick: () => appendOrReplaceValue(`${index + 1}`),
            className:
                "bg-primary-100 text-primary-500 border-2 border-primary-200",
            disabled: false
        })),
        {
            key: "backspace",
            digit: "⌫",
            onClick: () => setValue((value) => value.slice(0, -1)),
            className: "bg-neutral-300",
            disabled: value.length === 0
        },
        {
            key: 0,
            digit: "0",
            onClick: () => appendOrReplaceValue("0"),
            className:
                "bg-primary-100 text-primary-500 border-2 border-primary-200",
            disabled: value.length === 0 || firstChange
        },
        {
            key: "OK",
            digit: "OK",
            onClick: closeWith(() => {
                onConfirm(Number(value));
            }),
            className: "bg-primary-500 text-white",
            disabled: Number(value) < 1 || Number(value) > 99
        }
    ].map(({ key, digit, onClick, className, disabled }) => (
        <div
            data-testid={key}
            key={key}
            onClick={(e) => {
                e.stopPropagation();
                if (disabled) return;
                onClick();
            }}
            className={`
                press-effect grid aspect-square place-content-center rounded font-semibold
                ${className}
                ${disabled ? "grayscale" : ""}
            `}
        >
            {digit}
        </div>
    ));

    return (
        <Modal
            isOpen={isOpen}
            onCloseComplete={() => onCloseCallbackRef.current?.()}
            onBackgroundClick={closeWith(onCancel)}
        >
            <div className="flex flex-col gap-y-5 overflow-hidden rounded-modal bg-white pt-5 shadow-lg">
                <div className="text-center text-xl font-semibold text-primary-800">
                    {t("quantityNeeded")}
                </div>

                <div
                    data-testid="numPad"
                    className="mx-auto box-content w-48 space-y-5 px-10"
                >
                    <div className="rounded border border-neutral-300 text-center text-xl font-semibold leading-loose text-primary-800">
                        {value || "\u00a0"}
                    </div>

                    <div className="grid grid-cols-3 gap-3.5">{digits}</div>
                </div>

                <button
                    data-testid="back"
                    onClick={(e) => {
                        e.stopPropagation();
                        closeWith(onCancel)();
                    }}
                    className="border-t-2 border-primary-200 bg-primary-100 p-2 font-semibold leading-loose text-primary-500"
                >
                    {t("back")}
                </button>
            </div>
        </Modal>
    );
};
