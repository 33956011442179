import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";

import placeholderImage from "../../../assets/placeholder.png";
import {
    KitchenProduct,
    KitchenProductAddOn,
    KitchenProductAddOnOption
} from "../../types/KitchenMenu";
import { translate } from "../../utils/translations";
import { useRootAndSubState } from "../state/StateProvider";

type KitchenProductSelectorDialogProps = {
    product: KitchenProduct;
    onConfirm: (
        productId: number,
        barcode: string,
        quantity: number,
        addOnOptionIds: number[]
    ) => void;
    onCancel: () => void;
};

export const KitchenProductSelectorDialog = ({
    product,
    onConfirm,
    onCancel
}: KitchenProductSelectorDialogProps) => {
    useRootAndSubState("kitchen", "productSelector");

    const { t, i18n } = useTranslation();
    const [quantity, setQuantity] = useState(1);
    const [selectedOptionIds, setSelectedOptionIds] = useState(
        () => new Set<number>()
    );

    const ProductAddOnOption = ({
        id,
        label,
        name,
        type,
        checked,
        onChange
    }: {
        id: number;
        label: string;
        name?: string;
        type: string;
        checked: boolean;
        onChange: ChangeEventHandler<HTMLInputElement> | undefined;
    }) => {
        return (
            <div className="flex items-center bg-white p-3">
                <input
                    className="mr-2 h-5 w-5"
                    id={`option-${id}`}
                    type={type}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                />
                <label className="grow text-start" htmlFor={`option-${id}`}>
                    {label}
                </label>
            </div>
        );
    };

    const CheckboxGroup = ({
        options
    }: {
        options: KitchenProductAddOnOption[];
    }) => {
        return (
            <div className="grid grid-cols-2 gap-2">
                {options.map((option) => (
                    <ProductAddOnOption
                        key={option.id}
                        id={option.id}
                        label={translate(option.name, i18n)}
                        type="checkbox"
                        checked={selectedOptionIds.has(option.id)}
                        onChange={() => {
                            setSelectedOptionIds((optionIds) => {
                                const updatedOptions = new Set(optionIds);

                                if (optionIds.has(option.id)) {
                                    updatedOptions.delete(option.id);
                                } else {
                                    updatedOptions.add(option.id);
                                }

                                return updatedOptions;
                            });
                        }}
                    />
                ))}
            </div>
        );
    };

    const RadioGroup = ({
        id,
        options
    }: {
        id: number;
        options: KitchenProductAddOnOption[];
    }) => {
        return (
            <div className="grid grid-cols-2 gap-2">
                {options.map((option) => (
                    <ProductAddOnOption
                        key={option.id}
                        id={option.id}
                        label={translate(option.name, i18n)}
                        name={`${"add-on-" + id}`}
                        type="radio"
                        checked={selectedOptionIds.has(option.id)}
                        onChange={() => {
                            setSelectedOptionIds((optionIds) => {
                                const updatedOptionIds = new Set(optionIds);

                                options.forEach((addOnOption) =>
                                    updatedOptionIds.delete(addOnOption.id)
                                );
                                updatedOptionIds.add(option.id);

                                return updatedOptionIds;
                            });
                        }}
                    />
                ))}
            </div>
        );
    };

    const ProductAddOn = ({ addOn }: { addOn: KitchenProductAddOn }) => {
        return (
            <div className="mb-2 py-2 text-center last:mb-0">
                <div
                    className={`${
                        addOn.required &&
                        "after:ml-0.5 after:text-red-500 after:content-['*']"
                    }`}
                >
                    {translate(addOn.description, i18n)}
                </div>

                {addOn.multipleChoice ? (
                    <CheckboxGroup options={addOn.options} />
                ) : (
                    <RadioGroup id={addOn.id} options={addOn.options} />
                )}
            </div>
        );
    };

    return (
        <div className="z-10 flex h-full flex-col gap-3 bg-primary-100 p-3">
            <div className="flex flex-col items-center bg-white text-center">
                <img
                    src={product.imageUrl ?? placeholderImage}
                    className="h-20"
                />
                <span className="w-full overflow-hidden text-ellipsis text-xl font-semibold capitalize">
                    {translate(product.name, i18n).toLowerCase()}
                </span>
            </div>

            <div className="flex-1 divide-y divide-slate-200 overflow-y-auto">
                {product.addOns.map((productAddOn) => (
                    <ProductAddOn addOn={productAddOn} key={productAddOn.id} />
                ))}
            </div>

            <div className="my-2 flex items-center justify-between bg-white">
                <button
                    disabled={quantity === 1}
                    className="press-effect h-10 w-10 flex-none rounded bg-primary-500 text-center font-semibold leading-loose text-white disabled:opacity-30"
                    onClick={() => setQuantity((value) => value - 1)}
                >
                    -
                </button>
                {quantity}
                <button
                    className="press-effect h-10 w-10 flex-none rounded bg-primary-500 text-center font-semibold leading-loose text-white"
                    onClick={() => setQuantity((value) => value + 1)}
                >
                    +
                </button>
            </div>

            <div className="grid grid-cols-2 gap-1">
                <button
                    className="press-effect flex-none rounded bg-primary-500 p-2 text-center font-semibold leading-loose text-white"
                    onClick={onCancel}
                >
                    {t("cancel")}
                </button>
                <button
                    className="press-effect flex-none rounded bg-primary-500 p-2 text-center font-semibold leading-loose text-white disabled:opacity-30"
                    disabled={
                        !product.addOns
                            .filter((productAddOn) => productAddOn.required)
                            .every((productAddOn) =>
                                productAddOn.options.some((option) =>
                                    selectedOptionIds.has(option.id)
                                )
                            )
                    }
                    onClick={() =>
                        onConfirm(product.id, product.barcode, quantity, [
                            ...selectedOptionIds
                        ])
                    }
                >
                    {t("done")}
                </button>
            </div>
        </div>
    );
};
