import { useTranslation } from "react-i18next";

import { FadeIn } from "./Animations";

export const Spinner = () => {
    return (
        <div
            className="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary-500 border-r-transparent align-[-0.125rem] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
        ></div>
    );
};

export const SpinnerOverlay = () => {
    const { t } = useTranslation();

    return (
        <FadeIn
            key="spinner-overlay"
            className="fixed inset-0 z-10 grid place-content-center overflow-y-auto bg-primary-900 bg-opacity-50"
        >
            <div className="flex flex-col items-center gap-8">
                <div
                    className="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary-500 border-r-transparent align-[-0.125rem] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                ></div>
                <div className="text-l font-semibold text-white">
                    {t("pleaseWait")}
                </div>
            </div>
        </FadeIn>
    );
};
