import { captureConsoleIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { replayIntegration, browserTracingIntegration } from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import "./i18n";
import "./index.css";

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            browserTracingIntegration(),
            replayIntegration(),
            captureConsoleIntegration({ levels: ["error", "assert"] })
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
} else {
    console.warn("Could not find Sentry DSN. Skipping Sentry setup.");
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
