import { LogClient } from "../clients/LogClient";

export interface MemoryInfo {
    totalJSHeapSize: number;
    usedJSHeapSize: number;
    jsHeapSizeLimit: number;
}

declare global {
    interface Performance {
        memory: MemoryInfo;
    }
}

export const logMemoryUsage = (
    logClient: LogClient,
    memoryInfo: MemoryInfo
) => {
    logClient.info(
        `Current memory usage: { totalJSHeapSize: ${memoryInfo.totalJSHeapSize}; usedJSHeapSize: ${memoryInfo.usedJSHeapSize}; jsHeapSizeLimit: ${memoryInfo.jsHeapSizeLimit} }`
    );
};
