import { trace } from "firebase/performance";

import { orderListPageSize } from "../components/operator/OrderList";
import { getPerformanceInstance } from "../firebase";
import { OrderInsights, OrderResponse } from "../types/Order";
import { GetReceiptResponse } from "../types/PostCheckout";

export class OrderClient {
    constructor(private client: typeof fetch) {}

    public async list(date: string, offset: number): Promise<OrderResponse> {
        const limit = orderListPageSize;
        const t = trace(getPerformanceInstance(), "order-list");
        t.start();
        const response = await this.client(
            `/ezio/v1/orders?date=${date}&limit=${limit}&offset=${offset}`,
            {
                method: "GET"
            }
        );
        t.stop();

        return await response.json();
    }

    public async getReceipt(
        orderId: string,
        language: string
    ): Promise<string> {
        const t = trace(getPerformanceInstance(), "order-print");
        t.start();
        const cart = await this.client(`/ezio/v1/orders/${orderId}/receipt`, {
            method: "GET",
            headers: {
                "Accept-Language": language
            }
        }).then((response) => response.text());
        t.stop();
        return cart;
    }

    public async getReceiptUrl(
        orderId: string,
        language: string
    ): Promise<GetReceiptResponse> {
        const response = await this.client(
            `/ezio/v1/orders/${orderId}/receipt/url`,
            {
                headers: {
                    "Accept-Language": language
                }
            }
        );

        return response.json();
    }

    public async getRevenue(
        date: string,
        groupBy: string
    ): Promise<OrderInsights> {
        const response = await this.client(
            `/ezio/v1/orders/revenue?date=${date}&groupBy=${groupBy}`
        );
        return response.json();
    }
}
