import { useState } from "react";
import { useTranslation } from "react-i18next";

import placeholderImage from "../../../assets/placeholder.png";
import { KitchenMenu as Menu } from "../../types/KitchenMenu";
import { translate } from "../../utils/translations";
import { useRootState } from "../state/StateProvider";
import * as svg from "../svg";

export const KitchenMenuDialog = ({
    cartQuantity,
    kitchenMenu,
    onCancel
}: {
    cartQuantity: number;
    kitchenMenu: Menu;
    onCancel: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const {
        state: { accessibilityMode },
        dispatch
    } = useRootState("kitchen");

    const [currentFilter, setFilter] = useState<number>(0);

    const products = kitchenMenu.categories[currentFilter].products;

    const CategoryTab = ({ index }: { index: number }) => {
        return (
            <button
                onClick={() => {
                    setFilter(index);
                }}
                className={`
                    min-w-fit flex-1 rounded border-2 bg-white p-2 text-center text-xs font-bold capitalize last:flex-none
                    ${
                        index === currentFilter
                            ? "border-primary-800"
                            : "border-white"
                    }
                `}
            >
                {translate(kitchenMenu.categories[index].name, i18n)}
            </button>
        );
    };

    return (
        <>
            <div
                data-testid="kitchenMenu"
                className="z-10 flex h-full flex-col gap-3 bg-primary-100 p-3"
            >
                <div className="flex items-center justify-between">
                    <h1 className="text-3xl font-semibold leading-snug text-primary-800">
                        {t("theKitchen")}
                    </h1>
                    <div className="text-center">
                        <div className="rounded bg-slate-300 p-2">
                            {svg.shoppingCart}
                        </div>
                        <span className="absolute right-2 top-3 h-[0.75rem] w-[0.75rem] rounded-full bg-primary-500 text-[0.5rem] text-white">
                            {cartQuantity}
                        </span>
                    </div>
                </div>

                <div className="flex flex-wrap gap-2">
                    {kitchenMenu.categories.map((_, index) => (
                        <CategoryTab key={index} index={index} />
                    ))}
                </div>
                <div className="-mx-3 flex-1 overflow-y-auto">
                    <div
                        role="list"
                        data-testid="products"
                        className="grid grid-cols-3 gap-2 px-3"
                    >
                        {products.map((product) => (
                            <button
                                disabled={!product.available}
                                role="listitem"
                                data-testid={"fixtasten".concat(
                                    product.barcode
                                )}
                                key={`product-${product.id}`}
                                onClick={() =>
                                    dispatch({
                                        kind: "showKitchenProductSelector",
                                        product
                                    })
                                }
                                className={`
                                    press-effect grid
                                    place-items-center
                                    py-2
                                    ${
                                        accessibilityMode
                                            ? "grid-cols-2 gap-x-1"
                                            : "grid-cols-1"
                                    }
                                    ${!product.available ? "opacity-50" : ""}
                                    rounded
                                    bg-white
                                    px-4
                                    text-center text-xs font-semibold
                                    text-primary-800
                                `}
                            >
                                <img
                                    src={product.imageUrl ?? placeholderImage}
                                    className="h-12"
                                />
                                <span className="w-full overflow-hidden text-ellipsis capitalize">
                                    {translate(
                                        product.name,
                                        i18n
                                    ).toLowerCase()}
                                </span>
                                {!product.available && (
                                    <span className="pt-1 font-normal text-primary-400">
                                        {t("kitchenMenu.productUnavailable")}
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
                <button
                    className="press-effect flex-none rounded bg-primary-500 p-2 text-center font-semibold leading-loose text-white"
                    onClick={() => onCancel()}
                >
                    {t("back")}
                </button>
            </div>
        </>
    );
};
