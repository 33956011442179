import { getEzioId } from "./id";

type Fetch = typeof fetch;
type FetchParameters = Parameters<Fetch>;
type FetchReturn = ReturnType<Fetch>;

const fetchWithBaseUrl =
    (baseFetch: Fetch) =>
    (baseUrl: string) =>
    (...params: FetchParameters): FetchReturn => {
        const [req, info] = params;
        return baseFetch(typeof req === "string" ? baseUrl + req : req, info);
    };

const fetchWithEzioId =
    (baseFetch: Fetch) =>
    (...params: FetchParameters) => {
        const [req, info] = params;

        const ezioId = getEzioId();

        const ezioIdHeader = ezioId
            ? {
                  "self-checkout-terminal-id": ezioId
              }
            : undefined;

        const infoWithEzio: RequestInit = {
            ...info,
            headers: { ...info?.headers, ...ezioIdHeader }
        };

        return baseFetch(req, infoWithEzio);
    };

export const httpClient = fetchWithEzioId(
    fetchWithBaseUrl(fetch)(import.meta.env.VITE_RETO_URL)
);
