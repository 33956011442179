import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";

const firebaseConfig: FirebaseOptions = JSON.parse(
    import.meta.env.VITE_FIREBASE
);

const app = initializeApp(firebaseConfig);
getAnalytics(app);
const performance = getPerformance(app);

export function getPerformanceInstance() {
    return performance;
}
