import { useEffect, useRef } from "react";

import { useAnyState } from "./state/StateProvider";
import { LogClient } from "../clients/LogClient";
import { httpClient } from "../utils/http";

export const VersionController = ({ children }: { children: JSX.Element }) => {
    const minutesUntilNextRefresh = 10;

    const logClient = new LogClient(httpClient);

    const { state } = useAnyState();
    const lastUpdate = useRef(new Date());

    useEffect(() => {
        const remainingTime =
            lastUpdate.current.valueOf() +
            minutesUntilNextRefresh * 60 * 1000 -
            new Date().valueOf();

        const id = setTimeout(() => {
            if (state.kind === "welcome" && state.subState.kind === "idle") {
                logClient
                    .info("VersionController reset. Reloading window")
                    .then(() => window.location.reload());
            }
        }, Math.max(0, remainingTime));

        return () => clearTimeout(id);
    }, [state]);

    return children;
};
