import { AnimationLifecycles, motion } from "framer-motion";
import { MouseEventHandler, PropsWithChildren } from "react";

export const FadeIn = ({
    children,
    className,
    onClick,
    onUpdate,
    onAnimationStart,
    onAnimationComplete
}: PropsWithChildren<
    {
        className?: string;
        onClick?: MouseEventHandler<HTMLDivElement>;
    } & AnimationLifecycles
>) => (
    <motion.div
        onClick={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.15, ease: "easeOut" }}
        exit={{ opacity: 0 }}
        className={className}
        onUpdate={onUpdate}
        onAnimationStart={onAnimationStart}
        onAnimationComplete={onAnimationComplete}
    >
        {children}
    </motion.div>
);

export const SlideUp = ({
    children,
    className,
    onUpdate,
    onAnimationStart,
    onAnimationComplete
}: PropsWithChildren<{ className?: string } & AnimationLifecycles>) => (
    <motion.div
        initial={{ transform: "translateY(100%)" }}
        animate={{ transform: "translateY(0)" }}
        transition={{ ease: "easeOut" }}
        exit={{ transform: "translateY(100%)" }}
        className={className}
        onUpdate={onUpdate}
        onAnimationStart={onAnimationStart}
        onAnimationComplete={onAnimationComplete}
    >
        {children}
    </motion.div>
);

export const Zoom = ({
    children,
    className,
    onUpdate,
    onAnimationStart,
    onAnimationComplete
}: PropsWithChildren<{ className?: string } & AnimationLifecycles>) => (
    <motion.div
        initial={{ scale: 1.1 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.15, ease: "easeOut" }}
        className={className}
        onUpdate={onUpdate}
        onAnimationStart={onAnimationStart}
        onAnimationComplete={onAnimationComplete}
    >
        {children}
    </motion.div>
);
