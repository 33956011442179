import { useTranslation } from "react-i18next";

import { Dialog } from "./Dialog";
import * as svg from "./svg";

export const ProductNotFoundDialog = ({ onOk }: { onOk: () => void }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            image={svg.productNotFound}
            title={t("productNotFound")}
            primaryButton={{ title: t("okDismiss"), action: onOk }}
        />
    );
};
