import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "./Dialog";
import { useRootState } from "./state/StateProvider";
import * as svg from "./svg";
import { CheckoutError } from "../types/Cart";

type ConfirmCartProps = {
    onCancel: () => void;
};

export const Payment = ({ onCancel }: ConfirmCartProps) => {
    const { t } = useTranslation();

    const [confirmed, setConfirmed] = useState(false);

    const { state, dispatch } = useRootState("scan");

    const { subState } = state;

    const ConfirmCartDialog = () => (
        <Dialog
            image={svg.confirmCart}
            title={t("confirmCart")}
            primaryButton={{
                title: t("toPayment"),
                action: () => {
                    setConfirmed(true);
                    dispatch({ kind: "startPayment" });
                }
            }}
            secondaryButton={{ title: t("back"), action: onCancel }}
            onBackgroundClick={onCancel}
        />
    );

    const ProcessingPaymentDialog = () => (
        <Dialog image={svg.pos} title={t("interactWithTerminal")} />
    );

    const PaymentFailedDialog = ({ error }: { error: CheckoutError }) => (
        <Dialog
            image={svg.productNotFound}
            title={t([`checkoutError.${error.case}`, "checkoutError.default"])}
            primaryButton={{
                title: t("tryAgain"),
                action: () => {
                    dispatch({ kind: "startPayment" });
                }
            }}
            secondaryButton={{ title: t("back"), action: onCancel }}
        />
    );

    return (
        <>
            {!confirmed && <ConfirmCartDialog />}
            {confirmed && subState.kind === "checkingOut" && (
                <ProcessingPaymentDialog />
            )}
            {confirmed && subState.kind === "checkoutError" && (
                <PaymentFailedDialog error={subState.reason} />
            )}
        </>
    );
};
