import { AnimatePresence, motion, stagger, useAnimate } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRootState } from "./state/StateProvider";
import * as svg from "./svg";

const staggerEmojis = stagger(0.2, { from: "center" });

export const Feedback = () => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(-1);
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [ref, animate] = useAnimate();
    const { dispatch } = useRootState("checkoutSuccess");

    useEffect(() => {
        animate(
            ".emoji",
            { opacity: [0, 1] },
            {
                duration: 0.5,
                delay: staggerEmojis,
                ease: "linear"
            }
        );
    }, []);

    const rates = Array.from({ length: 5 }).map((_, index) => (
        <div
            data-testid={`rate${index}`}
            key={index}
            onClick={() => {
                setSelected(index);
                dispatch({ kind: "sendFeedback", rate: index + 1 }).then(() =>
                    setFeedbackSent(true)
                );
            }}
            className={`press-effect emoji }  text-primary-700`}
        >
            {selected >= index ? svg.starFilled : svg.starOutline}
        </div>
    ));

    const feedbackLabel = feedbackSent
        ? "endThanksForFeedback"
        : "feedbackQuestion";

    return (
        <div className="flex h-24 flex-col items-center bg-primary-200 p-6 text-primary-800">
            <span
                data-testid={feedbackLabel}
                className="font-semibold text-primary-800"
            >
                {t(feedbackLabel)}
            </span>
            <AnimatePresence>
                {!feedbackSent && (
                    <motion.div
                        ref={ref}
                        className={`flex gap-4`}
                        exit={{ opacity: 0, dur: 1.5 }}
                    >
                        {rates}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
